import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CUSTOMERFEEDBACK,
    DELETE_CUSTOMERFEEDBACK,
    CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING,
    SET_CUSTOMERFEEDBACK_LIST,
    UPDATE_CUSTOMERFEEDBACK,
} from "../constants";
import { formatCustomerFeedbacks } from "./settingsActionsUtils";

/* CUSTOMERFEEDBACK LIST */
export const fetchCustomerFeedbacks = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.customerFeedbacks.list)
            .then((response) => {
                const customerFeedbacks = formatCustomerFeedbacks(response.data);
                dispatch({
                    type: SET_CUSTOMERFEEDBACK_LIST,
                    payload: keyBy(customerFeedbacks, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

/* CUSTOMERFEEDBACK */
export const getCustomerFeedback = (id) => {
    return async (dispatch) => {
        const response = await axios
            .get(API.customerFeedbacks.edit + id)
            .then((response) => {
                return response;
            })
            .catch((err) => err);
        return response;
    };
};

// CUSTOMERFEEDBACKS
export const createCustomerFeedback = (newCustomerFeedback) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.customerFeedbacks.create, newCustomerFeedback)
            .then((response) => {
                const customerFeedback = formatCustomerFeedbacks(response.data);
                dispatch({ type: ADD_NEW_CUSTOMERFEEDBACK, payload: customerFeedback });
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCustomerFeedback = (updatedCustomerFeedback) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.customerFeedbacks.edit}${updatedCustomerFeedback?._id}`, updatedCustomerFeedback)
            .then((response) => {
                const customerFeedback = formatCustomerFeedbacks(response.data);
                dispatch({ type: UPDATE_CUSTOMERFEEDBACK, payload: customerFeedback });
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCustomerFeedbacks = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.customerFeedbacks.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CUSTOMERFEEDBACK, payload: Ids });
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: CUSTOMERFEEDBACK_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
