import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_WORKORDER,
    DELETE_WORKORDER,
    WORKORDER_FORM_TOOGLE_LOADING,
    SET_WORKORDER_LIST,
    UPDATE_WORKORDER,
} from "../constants";
import { formatWorkorders } from "./settingsActionsUtils";

/* WORKORDER LIST */
export const fetchWorkorders = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.workorders.list)
            .then((response) => {
                const workorders = formatWorkorders(response.data);
                dispatch({
                    type: SET_WORKORDER_LIST,
                    payload: keyBy(workorders, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// WORKORDERS
export const createWorkorder = (newWorkorder) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.workorders.create, newWorkorder)
            .then((response) => {
                const workorder = formatWorkorders(response.data);
                dispatch({ type: ADD_NEW_WORKORDER, payload: workorder });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "success",
                //         message: "Parte de trabajo creado con éxito.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateWorkorder = (updatedWorkorder) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.workorders.edit}${updatedWorkorder?._id}`, updatedWorkorder)
            .then((response) => {
                const workorder = formatWorkorders(response.data);
                dispatch({ type: UPDATE_WORKORDER, payload: workorder });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "info",
                //         message: "Parte de trabajo actualizado.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteWorkorders = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.workorders.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_WORKORDER, payload: Ids });
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                // dispatch({
                //     type: SHOW_NOTIFICATION,
                //     payload: {
                //         show: true,
                //         status: "info",
                //         message: "Parte de trabajo eliminado.",
                //     },
                // });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: WORKORDER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
